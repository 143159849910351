import React, { useContext, useEffect, useState } from 'react'
import userImage from '../../assets/default-user-image.png'
import logo from '../../assets/gharabawy/logo-two.svg';
import { darkMode } from '../../Context/DarkModeContext';
import MobileMenu from './MobileMenu/MobileMenu';
import { useLocation } from 'react-router-dom';

export default function Header() {

    let location = useLocation()

    let { ToggleDarkTheme, darkTheme } = useContext(darkMode)

    const [openMenu, setOpenMenu] = useState(false);

    let open = () => {
        let menustate = openMenu ;
        openMenu ? menustate = false : menustate = true ;
        setOpenMenu(menustate);
    }

    useEffect(()=>{
        let menustate = openMenu ;
        if(openMenu){
            menustate = false;
        }
        setOpenMenu(menustate);
    } , [location.pathname])

    return <>
        <header className='dashboard-header'>
            <div className="container-fluid">
                <div className="user hidden-sm">
                    <img src={userImage} alt="" className='w-100 h-100' />
                </div>
                <div className="search-theme hidden-sm">
                    <div className="search-input">
                        <input type="text" name='search' className='search-input' />
                        <button className='filter'>
                            <i class="fa-solid fa-filter"></i>
                        </button>
                        <button className='search-btn'>
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                    <button className="dark-theme" onClick={ToggleDarkTheme}>
                        <i class="fa-regular fa-moon"></i>
                    </button>
                </div>
                <button className='mobile-menu hidden-lg' onClick={open}>
                    <i className="fa-solid fa-bars"></i>
                </button>
                <div className="logo">
                    {/* <img src={logo} alt="" className='w-100 h-100' /> */}
                </div>
            </div>
        </header>

        <MobileMenu open={openMenu} methoud={open} />
    </>
}
