import React from 'react'
import "../cv/cv.css"
import cv from '../../../../assets/taha-assets/cv.png'
export const Cv = () => {
    return (
        <div className='cv'>
            <h1>
                السيرة الذاتية 
            </h1>
            <img className='w-75' src="" alt="" srcSet={cv} />
        </div>
    )
}
