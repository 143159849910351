import React from 'react'
import lightLogo from '../assets/taha-assets/5.png'
import companyLogo from '../assets/company-logo.png'

export default function Footer() {
    return <>
        <footer className='footer'>
            <div className='container'>
                <div className='contact-section'>
                    <div className='footer-logo'>
                        <img className='w-100 h-100' alt='' src={lightLogo} />
                    </div>
                    <h4 className='text-center'>
                        تواصل معنا عبر
                    </h4>
                    <div className='row py-2 w-100 '>
                        {/* <div className='email col-lg-3 col-12 text-start'>
                        AhmedFadl@gmail.com
                        mahmoud-elgharabawy.com
                        <i className="fa-regular fa-envelope"></i>
                    </div> */}
                        {/* <div className='col-lg-3 col-12 text-end'>
                        01140 665 667
                        0111 35 333 06
                        <i className="fa-solid fa-phone-flip"></i>
                    </div> */}
                        <div className='col-lg-3 col-12 text-end'>
                            01070099427
                            <i className="fa-solid fa-phone-flip"></i>
                        </div>
                        <div className='col-lg-3 col-12 text-end'>
                            01070099594
                            <i className="fa-solid fa-phone-flip"></i>
                        </div>
                        {/* <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div>
                    <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div>
                    <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div> */}
                    </div>
                    <div className='footer-social-media py-1'>
                        <a href='https://www.facebook.com/profile.php?id=100063693709855&mibextid=uzlsIk' target='_blank'>
                            <i className="fa-brands fa-facebook"></i>
                        </a>
                        {/* <a href='https://instagram.com/mahmoud.elgharabawy.52?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target='_blank'>
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href='https://www.tiktok.com/@mahmoud.elgharaba1?_t=8irZWjFn0FR&_r=1' target='_blank'>
                            <i className="fa-brands fa-tiktok"></i>
                        </a>
                        */}
                        <a href='https://www.tiktok.com/@tahasalah_?_t=8ocDykjRYeN&_r=1' target='_blank'>
                            <i className="fa-brands fa-tiktok"></i>
                        </a> 
                        <a href='https://www.youtube.com/@drtahasalah' target='_blank'>
                            <i className="fa-brands fa-youtube" ></i>
                        </a>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='copyright-logo'>
                        <img className='w-100 h-100' src={companyLogo} alt='' />
                    </div>
                    <p>
                        حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.
                    </p>
                </div>
            </div>
        </footer>
    </>
}
