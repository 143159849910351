import React, { useContext, useEffect, useRef, useState } from 'react'
import SectionContainer from '../../../Components/Dashboard/Used/SectionContainer'
import DashboardLessonBox from '../../../Components/Dashboard/Lessons/DashboardLessonBox'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useParams } from 'react-router-dom'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'
import UploadVideoS3 from '../../../Components/Dashboard/UploadVideoS3/UploadVideoS3'

export default function Lessons() {

    const { userAuth } = useContext(auth)
    const { unit_id } = useParams()

    const [controller, setcontroller] = useState(null)

    const [currentLesson, setCurrentLesson] = useState({})
    const [updateData, setUpdateData] = useState({})

    const [lessons, setLessons] = useState(false)
    const [createLessonData, setCreateLessonData] = useState({ name: '', description: '', views:'' , pending: false })

    const [isUploading, setisUploading] = useState(false)

    const [uploadProgress, setuploadProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const chooseVideoType = (e) => {
        let data = { ...createLessonData }
        if (e.target.value == 'vdocipher') {
            data['vdocipher'] = ''
            if (data['cloud_server'] !== undefined) {
                delete data['cloud_server']
            }
        } else if (e.target.value == 'cloud_server') {
            data['cloud_server'] = ''
            if (data['vdocipher'] !== undefined) {
                delete data['vdocipher']
            }
        } else {
            delete data['vdocipher']
            delete data['cloud_server']
        }
        setCreateLessonData(data)
    }


    const updateCreareData = (e) => {
        let data = { ...createLessonData };
        data[e.target.name] = e.target.value;

        setCreateLessonData(data)
    }


    const getLessons = () => {
        axios.get(`${config.basURL}dashboard/lesson-overview/${unit_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            console.log(res.data)
            setLessons(res.data.lessons)
        }).catch(err => console.log(err))
    }

    const createLesson = (e) => {
        // e.preventDefault()
        setIsLoading(true)
        // console.log(createLessonData)
        let valid = new Validator;
        valid.setKey('name').setValue(createLessonData.name).required();
        createLessonData.vdocipher != undefined && valid.setKey('vdocipher').setValue(createLessonData.vdocipher).required();
        createLessonData.cloud_server != undefined && valid.setKey('cloud_server').setValue(createLessonData.cloud_server).required();
        let disblayError;

        if (valid.haveError) {
            setIsLoading(false)
            for (let key in valid.getErrors()) {
                disblayError += valid.getErrors()[key]
            }
            Swal.fire({
                text: disblayError,
                icon: 'error'
            })
            return;
        }

            // controller.current = new AbortController();
            let data = {...createLessonData}
            data.unit = unit_id

        axios.post(`${config.basURL}dashboard/lesson-overview/${unit_id}`, data, {
            // signal: controller.current.signal,
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
                'Content-Type': 'multipart/form-data',
            },
            // onUploadProgress: (progressEvent) => {
            //     const { loaded, total } = progressEvent;
            //     const percent = Math.floor((loaded * 100) / total)
            //     setuploadProgress(percent)
            // }
        }).then(res => {
            setuploadProgress(0)
            setIsLoading(false)
            getLessons()
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }


    useEffect(() => {
        getLessons()
    }, [])

    return <>
        <div className="container-fluid dashboard-lessons-page">
            <h1>
                الدروس
            </h1>
            <div className="new-lesson" onSubmit={createLesson}>
                <div className='lesson-name'>
                    <input type="text" name='name' className='lesson-name-input' placeholder='أسم الدرس' onChange={updateCreareData} />
                    <input type="text" name='views' className='lesson-name-input' placeholder='عدد مرات مشاهده الدرس' onChange={updateCreareData} />
                    <select name="" id="" onChange={chooseVideoType}>
                        <option> اختر نوع الفيديو </option>
                        <option value="vdocipher"> vdocipher </option>
                        <option value="cloud_server"> ارفع من الجهاز </option>
                    </select>
                    <select name="pending" id="" onChange={updateCreareData}>
                        <option value="true"> غير مفعل </option>
                        <option value="false"> مفعل </option>
                    </select>
                    {
                        createLessonData['vdocipher'] != undefined && <input type="text" name='vdocipher' className='lesson-name-input' placeholder='vdocipher ' onChange={updateCreareData} />
                    }
                    {
                        createLessonData['cloud_server'] != undefined && <>
                        {/* <UploadVideoS3 
                        name={'cloud_server'} 
                        change={updateCreareData} 
                        setuploadProgress={setuploadProgress} 
                        setIsLoading={setisUploading} 
                        createLessonData={createLessonData}
                        setCreateLessonData={setCreateLessonData}
                        controller={setcontroller}
                        /> */}
                        <input type="text" className='lesson-name-input' name='cloud_server' onChange={updateCreareData} placeholder='ارفع من الجهاز' />
                        </>
                    }
                    {
                        isUploading && <>
                            <div className='dashboard-upload-header'>
                                <p> جاري رفع الفيديو.... </p>
                                <button onClick={()=> controller && controller.cancel()}>
                                    الغاء
                                </button>
                            </div>
                            <div className='upload-progress'>
                                <div className='text'>{uploadProgress}%</div>
                                <div className="bar">
                                    <div style={{ width: `${uploadProgress}%` }}></div>
                                </div>
                            </div>
                        </>
                    }

                    <textarea name="description" className='lesson-name-input' id="" placeholder='وصف الدرس' onChange={updateCreareData}></textarea>
                    <button className={`save-data ${isUploading || isLoading ? 'disabled' : ''}`} onClick={createLesson} disabled={isUploading || isLoading ? true : false}>
                        {
                            isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'حفظ البيانات'
                        }
                    </button>
                </div>
            </div>
            {
                lessons && <SectionContainer sectionTitle={lessons.name}>
                    {lessons.length ? lessons.map(lesson => <DashboardLessonBox key={lesson.id} refresh={getLessons} unitId={unit_id} data={lesson} />) : <p className='text-center'>لا يوجد دروس في هذه الوحده</p>}
                </SectionContainer >
            }
        </div>
    </>
}
