import React, { useEffect, useRef, useState } from 'react'
import './Tahastyle.css';
// import teacher from '../../../assets/taha-assets/teacher-banner.png'
import tahaSalah from '../../../assets/taha-assets/taha-salah.png'
import { Link, useNavigate } from 'react-router-dom'
import config from '../../../config'
import axios from 'axios'
import Placeholder from '../../../Components/Used/Placeholder'
import { Header } from './header/Header';
import { Properties } from './properties/Properties';
import { About } from './about/About';
import { Best} from './bestTh/Best';

export default function TahaHome() {

    const navigate = useNavigate()
    const [allCourses, setAllCourses] = useState(false)
    const [displayCourses, setDisplayCourses] = useState(false)
    const [filter, setFilter] = useState(4)
    const circleElement = useRef();

    let navigateToCourses = (id) => {
        navigate(`/courses?year=${id}`)
    }

    const getCourses = () => {
        axios.get(`${config.basURL}course/course-list`, {
            headers: {
                'Authorization': config.ApiKey,
            }
        }).then(res => {
            console.log(res.data)
            let data = res.data.courses
            setAllCourses(data)
            let init = data.filter((course, index) => {
                if (index < 6) {
                    return course.year.id == filter;
                }
            })
            setDisplayCourses(init)
        }).catch(err => {
            console.log(err)
        })
    }

    const selectAction = (e) => {
        // remove select class from all elements
        let elements = circleElement.current.children;
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("active");
        }
        // add selected class to selected element
        e.target.className = "active"
        let year = e.target.getAttribute('data-id')
        let init = allCourses.filter((course, index) => {
            if (index < 6) {
                return course.year.id == year;
            }
        })
        setDisplayCourses(init)
    };

    useEffect(() => {
        getCourses()
    }, [])

    return <>
        {/* header */}
        <Header/>
        {/* start about section */}
        <About/>
        {/* Properties */}
            <Properties/>
        {/* start best plateforms */}
            {/* <Best/> */}
        {/* // start courses section */}
        <section className='py-5 taha-courses'>
            <div className='container'>
                <h4 className='text-center mb-5'>
                    كورسات د /  طه صلاح
                </h4>
                <ul className='course-filter' ref={circleElement}>
                    <li className={`${filter === 2 && 'active'}`} onClick={selectAction} data-id='2'> الصف الاول الثانوي </li>
                    <li className={`${filter === 3 && 'active'}`} onClick={selectAction} data-id='3'> الصف الثاني الثانوي </li>
                    <li className={`${filter === 4 && 'active'}`} onClick={selectAction} data-id='4'> الصف الثالث الثانوي </li>
                </ul>
                <div className='row gap-2 mb-2'>
                    {
                        displayCourses ? (displayCourses.length > 0 ? displayCourses.map(course => <div className='col-lg-3' key={course.id}>
                            <div className="image">
                                <img src={`${course.cover}`} alt="" className='w-100 h-100' />
                            </div>
                            <div className="body">
                                <div className='name-price'>
                                    <h6> {course.name} </h6>
                                    <p className='price m-0'> {course.free ? 'مجاني' : `ج ${course.price}`} </p>
                                </div>
                                <p> {course.about} </p>
                            </div>
                            <div className='content'>
                                <p className='units'>
                                    <i className="fa-solid fa-shapes"></i>
                                    {
                                        course.course_units.length
                                    }
                                    الوحدات
                                </p>
                                <p className='lessons'>
                                    <i className="fa-solid fa-book-open-reader"></i>
                                    {
                                        course.lessons_count
                                    }
                                    الدروس
                                </p>
                                <p className='exams'>
                                    <i className="fa-solid fa-file"></i>
                                    {
                                        course.course_file.length
                                    }
                                    الفايلات
                                </p>
                            </div>
                            <div className="course-footer">
                                <Link to={`course-preview/${course.id}`}>
                                    الذهاب للكورس <div><i className="fa-solid fa-chevron-left"></i></div>
                                </Link>
                            </div>
                        </div>) : <p className='col-12 text-center'> لا يوجد كورسات حتي الان </p>) : <Placeholder />
                    }
                </div>
            </div>
        </section>

    </>
}
